
import Vue from 'vue'

export default Vue.extend({
  data: () => ({ }),

  computed: {
    policies () {
      return this.$store.state.regInfo.policies
    }
  }
})
