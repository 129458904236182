<template>
  <div class="register pt-4">
    <v-row no-gutters>
      <v-btn class="no-print mr-3" href="https://dancestudio-pro.com/online/index.php?account_id=17992">Register Online</v-btn>
    </v-row>
    <h2>Studio Information and Policies</h2>
    <studio-policy></studio-policy>
  </div>
</template>

<script>
// @ is an alias to /src
import StudioPolicy from '@/components/StudioPolicy.vue'

export default {
  name: 'Home',
  components: {
    StudioPolicy
  },
  data: () => ({ }),
  methods: {
    print () {
      window.print()
    }
  }
}
</script>
